export default {
  siteTitle: 'Get bonus',
  blogerName: 'ISADX',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/isadiax'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@IsaDiax'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/isadias.s2/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/c841fb627',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/c946482ae',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c0f7b6c61',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cadbd8caa',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/ca9dcea2f',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c3cb04873',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/ca3213ac4',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c588a81fe',
      gameTitle: 'Jet Air',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c04ed5df7',
      gameTitle: 'LEGZO PUNK',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>ISADX</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'ISADX',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
